import React from 'react';
import HeroWork from '../components/HeroWork';
import Header from  '../components/Header';
import Footer from  '../components/Footer';
import Services from '../components/Services';
import ScrollLayout from '../components/ScrollLayout';
import { Helmet } from 'react-helmet';


const Work = () => {
  return (

    <div>
      <Helmet>
        <title>Work Of NoodleWorks</title>
        <meta
          name="description"
          content="Explore the portfolio of NoodleWorks and see how help local businesses improve their online presence with stunning web development and digital marketing services."
        />
        <meta name="keywords" content="NoodleWorks, Web Development, Digital Marketing, Portfolio, Services, Web Solutions" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Work Of NoodleWorks",
            "description": "Discover how NoodleWorks delivers exceptional web solutions for businesses with our portfolio showcasing our work.",
            "url": "https://thenoodleworks.com/Work"
          })}
        </script>
      </Helmet>
      <ScrollLayout>
        <header>
          <Header />
        </header>
        <section>
          <HeroWork />
        </section>
        <section>
          <Services />
        </section>
        <footer>
          <Footer />
        </footer>
      </ScrollLayout>
    </div>
  )
}

export default Work

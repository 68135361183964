import React from 'react'
import WhoAreWeHomepage from '../components/WhoAreWeHomepage';
import HomepageStats from '../components/HomepageStats';
import WhyUs from '../components/WhyUs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HeroHomepage from '../components/HeroHomepage';
import WhatWeDo from '../components/WhatWeDo';
import ScrollLayout from '../components/ScrollLayout';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Homepage = () => {
    useEffect(() => {
        document.title = 'NoodleWorks';
      }, []);
  return (
    <div>
      <Helmet>
      <title>NoodleWorks</title>
    <meta
      name="description"
      content="We are a team of passionate web experts, driven by Grit, Trust, and Creativity. We specialize in making websites for local businesses, creating fresh designs to attract customers and boost your visibility online."
      
    />
    <meta
      name="keywords"
      content="noodleworks, the noodle works, noodle works, Web developers,  website revamp, digital marketing, local business websites, web design, business website redesign"
    />
    <meta name="author" content="NoodleWorks" />
    <link rel="canonical" href="https://thenoodleworks.com/" />
    <meta name="robots" content="index, follow" />
    <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "NoodleWorks",
            "url": "https://thenoodleworks.com/",
            "description": "The NoodleWorks specializes in transforming outdated websites into modern, engaging platforms for businesses.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://thenoodleworks.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          })}
        </script>
      </Helmet>
      <ScrollLayout>
        <header>
          <Header />
        </header>
        <section>
          <HeroHomepage/>
        </section>
        <section>
          <WhyUs />
        </section>
        <section>
          <HomepageStats />
        </section>
        <section>
          <WhoAreWeHomepage />
        </section>
        <section>
          <WhatWeDo/>
        </section>
        <footer>
          <Footer />
        </footer>
      </ScrollLayout>
    </div>
  )
}

export default Homepage

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import HeroContact from '../components/HeroContact';
import Footer from '../components/Footer';
import ContactFormWithFlex from '../components/ContactFormWithFlex';
import ScrollLayout from '../components/ScrollLayout';

const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact NoodleWorks';
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact NoodleWorks</title>
        <meta
          name="description"
          content="Get in touch with NoodleWorks for expert website revamps and digital marketing solutions. Fill out our contact form to start your journey with us!"
        />
        <meta
          name="keywords"
          content="NoodleWorks Contact, Get in Touch, Website Revamp, Digital Marketing, Contact Us"
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact NoodleWorks",
            "description": "Reach out to NoodleWorks for all your website and digital marketing needs.",
            "url": "https://thenoodleworks.com/ContactUs",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-806-544-7572",
              "contactType": "Inquiries",
              "areaServed": "US",
              "availableLanguage": "English"
            }
          })}
        </script>
      </Helmet>
      <ScrollLayout>
        <header>
          <Header/>
        </header>
        <HeroContact />

        <main>
          <ContactFormWithFlex />
        </main>
        <footer>
          <Footer />
        </footer>
      </ScrollLayout>
    </div>
  );
};

export default ContactUs;

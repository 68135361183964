import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollLayout from '../components/ScrollLayout';

import HeroAbout from '../components/HeroAbout';
import Header from '../components/Header';
import Values from '../components/Values';
import Process from '../components/Process';
import Footer from '../components/Footer';

const About = () => {


  return (
    <div>
      <Helmet>
        <title>About NoodleWorks</title>
        <meta
          name="description"
          content="Learn more about NoodleWorks, our mission, values, and the process that sets us apart in delivering exceptional web solutions."
        />
        <meta name="keywords" content="NoodleWorks, About Us, Web Development, Digital Marketing, Values, Process" />
        <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "About NoodleWorks",
    "description": "Learn more about NoodleWorks and our mission to create stunning websites for businesses.",
    "url": "https://thenoodleworks.com/about"
  })}
</script>
      </Helmet>
      <ScrollLayout>
      <header>
        <Header />
      </header>
      <section>
        <HeroAbout />
      </section>
      <section>
        <Values />
      </section>
      <section>
        <Process />
      </section>
      <footer>
        <Footer />
      </footer>

      </ScrollLayout>
    </div>
  );
};

export default About;
